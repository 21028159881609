import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import CookiePopup from "./components/acceptCookie";
import LoginDialog from "./components/loginDialog";
import NotFound from "./components/notFound";
import TopHeader from "./components/topHeader";
import Footer from "./components/footer";
import HomePage from "./homeview";
import AuthRoute from "./routes/appRoutes";
import store from "./store/store";
import AboutView from "./views/aboutView";
import ArchiveListView from "./views/archiveListView";
import ArchiveView from "./views/archiveView";
import BlackListView from "./views/blackListView";
import DashboardView from "./views/dashboardView";
import DetailView from "./views/detailView";
import ContactView from "./views/contactView";
import TermsAndConditionView from "./views/termsAndConditionView";
import PublicStatusView from "./views/publicStatusView";
import { AuthProvider } from './context/AuthContext';

import "./App.css";

function App() {
  const urlsToHideFooter = "/archive/";
  const shouldHideFooter =
    window.location?.pathname?.includes(urlsToHideFooter);

  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <BrowserRouter>
            <TopHeader />
            <Box minHeight={"calc(100vh - 150px)"}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/archives" element={<DetailView />} />
                <Route path="/about" element={<AboutView />} />
                <Route path="/terms" element={<TermsAndConditionView />} />
                <Route path="/contact" element={<ContactView />} />
                <Route path="/status" element={<PublicStatusView />} />
                <Route path="/archive/:shortId/*" element={<ArchiveView />} />
                <Route
                  path="/bannedsites"
                  element={<AuthRoute element={<BlackListView />} />}
                />
                <Route path="/list/*" element={<ArchiveListView />} />
                <Route
                  path="/dashboard"
                  element={<AuthRoute element={<DashboardView />} />}
                />
                <Route path="/diw55ketc" element={<LoginDialog open={true} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
            {!shouldHideFooter && (
              <Footer/>
            )}
          </BrowserRouter>
          <CookiePopup />
        </AuthProvider>
      </Provider>
    </>
  );
}

export default App;
