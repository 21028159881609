import React from "react";
import { Dialog, DialogTitle, Box } from "@mui/material";
import GoogleLoginScript from './googleLoginScript';
import Login from "./login";

const LoginDialog = ({ open, onClose }) => {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>User Authentication</DialogTitle>
      <Box padding={4}>
        <Login />
      </Box>
      <Box padding={4} marginX={8}>
        <GoogleLoginScript/>
      </Box>
    </Dialog>
  );
};

export default LoginDialog;
