import React, { createContext, useContext, useState, useCallback } from 'react';
import { googleLogout } from '@react-oauth/google';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleGoogleLogin = useCallback(async (credential) => {
        setLoading(true);
        setError(null);
        
        try {
            const response = await axios.post('/auth/google', { credential });
            setProfile(response.data.user);
            return response.data;
        } catch (err) {
            setError(err.response?.data?.errors || 'Authentication failed');
            throw err;
        } finally {
            setLoading(false);
        }
    }, []);

    const logout = useCallback(() => {
        googleLogout();
        setProfile(null);
        setError(null);
    }, []);

    const value = {
        profile,
        loading,
        error,
        handleGoogleLogin,
        logout,
        isAuthenticated: !!profile
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export { AuthContext };