import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

// Create React App already supports environment variables
// No need to load dotenv manually
const googleClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const googleClientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;
const googleRedirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI;

if (!googleClientId) {
  console.error('Google Client ID is not configured in environment variables');
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={googleClientId} clientSecret={googleClientSecret} redirectUri={googleRedirectUri}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Remove the error event listener if it's not needed for production
if (process.env.NODE_ENV === 'prod') {
  // Consider using a proper error tracking service instead
  window.removeEventListener("error", () => {});
} else {
  window.addEventListener("error", function (e) {
    console.error("App Error:", {
      message: e.message,
      url: e.filename,
      line: e.lineno,
      column: e.colno,
      stack: e.error?.stack
    });
  });
}
