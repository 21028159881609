import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { CircularProgress } from '@mui/material';
import { useAuth } from '../context/AuthContext';

function GoogleLoginScript() {
    const { profile, loading, error, handleGoogleLogin, logout } = useAuth();

    const handleSuccess = async (response) => {
        try {
            await handleGoogleLogin(response.credential);
        } catch (error) {
            console.error('Google login failed:', error);
        }
    };

    const handleError = (error) => {
        console.error('Google login error:', error);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            {error && (
                <div style={{ color: 'red', marginBottom: '1rem' }}>
                    {error}
                </div>
            )}
            
            {profile ? (
                <div>
                    <img 
                        src={profile.picture} 
                        alt="user" 
                        style={{ 
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px'
                        }}
                    />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <button 
                        onClick={logout}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#f44336',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Log out
                    </button>
                </div>
            ) : (
                <GoogleLogin 
                    onSuccess={handleSuccess} 
                    onError={handleError}
                    useOneTap
                    theme="filled_blue"
                />
            )}
        </div>
    );
}

export default GoogleLoginScript;